<template>
  <div id="page-sendouts">
    <h5 class="m-4">{{$t('AUTH.MENU.SENDOUT')}}</h5>

    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.SENDOUT.CONFIRM_DELETE')"
      ref="deleteSendoutConfirm"
      type="success"
      @confirm="on_delete_sendout_confirm"
    />
    <Confirm
      :title="$t('COMMON.CONFIRM')"
      :message="$t('PAGES.SENDOUT.CONFIRM_START')"
      ref="confirmModal"
      @cancel="cancel_confirm_start"
      @confirm="confirm_start"
    />

    <SendoutTable
      :items="sendouts"
      @previewSendout="preview_sendout_clicked"
      @show_history_toggled="show_history_toggled"
      @createSendoutClicked="on_create_new_sendout"
      @selectSendoutClicked="select_sendout"
      @deleteSendoutClicked="delete_sendout_clicked"
      @startSendout="start_sendout"
      @created="sendout_created"
    />

    <SendoutModal
      ref="sendout-modal"
      :item="selected_item"
      @create="create_sendout"
      @update="update_sendout"
    />

    <SendoutPreviewEmailModal
      ref="preview-email-modal"
      @send_preview="send_preview_email"
    />

    <SendoutPreviewSMSModal
      ref="preview-sms-modal"
      :item="selected_item"
      @send_preview="send_preview_sms"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import SendoutTable from '@/view/pages/ml/sendout/SendoutTable.vue';
import Confirm from '@/view/components/Confirm.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import SendoutModal from '@/view/pages/ml/sendout/SendoutModal.vue';
import SendoutPreviewEmailModal from '@/view/pages/ml/sendout/SendoutPreviewEmailModal.vue';
import SendoutPreviewSMSModal from '@/view/pages/ml/sendout/SendoutPreviewSMSModal.vue';

export default {
  name: 'SendoutPage',
  components: {
    SendoutPreviewSMSModal,
    SendoutTable,
    Confirm,
    SendoutModal,
    SendoutPreviewEmailModal,
  },
  mixins: [ toasts ],
  data() {
    return {
      sendout_id_to_delete: null,
      previewSendoutId: null,
      previewEmailTo: null,

      company: {},
      countries: [],
      showHistory: false,
      hasGoogleMaps: false,
      isTmpReview: false,
      selected_item: {
        prio: 'def',
        company_id: '',
        status: '',
        template_id: null,
        type: 'email',
        name: '',
        search_options: null,
        num_to_send: 0,
        overrides: {
          subject: '',
          preview: '',
          from_name: '',
          reply_to: null,
          from_email: null
        }
      },
      sendouts: [],
      regions: [],
      communes: [],
    };
  },
  watch: {
    template_review(newVal) {
      if (!newVal) this.isTmpReview = newVal;
    },
    currentCompanyId(newValue, oldvalue) {
      if (newValue) {
        this.load_sendouts();
      }
    },
    currentPeriodId(newValue, oldvalue) {
      if (newValue) {
        this.load_sendouts();
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId']),
  },

  methods: {
    sendout_created(sendout) {
      this.load_sendouts();
    },
    reset_selected_item() {
      this.selected_item = {
        prio: 'def',
        company_id: '',
        status: 'DRAFT',
        template_id: null,
        type: 'email',
        name: '',
        search_options: null,
        num_to_send: 0,
        overrides: {
          subject: '',
          preview: '',
          from_name: '',
          from_id: '',
          reply_to: null,
          from_email: null
        }
      }
    },
    on_create_new_sendout() {
      this.reset_selected_item();
      this.show_sendout_modal();
    },
    show_sendout_modal() {
      this.$refs['sendout-modal'].show();
    },
    async select_sendout(sendout_id) {
      this.selected_item = await this.get_sendout(sendout_id);
      this.show_sendout_modal();
    },

    preview_sendout_clicked(sendout) {
      this.selected_item = sendout;
      sendout.type === 'sms'
        ? this.$refs['preview-sms-modal'].show()
        : this.$refs['preview-email-modal'].show();
    },

    async load_regions() {
      // get regions
      const res = await axios.get('https://zip.memlist.se/api/v1/regions_communes');
      if (res.status === 200) {
        // region load
        for (const [k, region] of Object.entries(res.data.regions)) {
          const communes = [];
          for (const [key, comm] of Object.entries(region.communes)) {
            communes.push({ value: key, text: comm });
          }

          this.regions.push({
            text: region.region,
            value: region.region_code,
            communes: communes
          });
        }
      } else {
        // no region found
      }

      // country
      const res1 = await axios.get('/location/countries');

      if (res1.status === 200) {
        this.countries = res1.data;
      } else {
      }
    },
    show_history_toggled(show) {
      this.showHistory = show;

      this.load_sendouts();
    },
    async load_data() {
      if (!this.currentCompanyId) {
        return;
      }

      await this.load_settings();
      await this.load_sendouts();
      await this.load_regions();
    },
    async load_settings() {
      try {
        const res = await axios.get(`/company/admin?company_id=${this.currentCompanyId}`)

        if (res.status === 200) {
          this.company = res.data;
        }

        this.queryOptions = [];

        for (var i = 0; i < this.company.queries.length; ++i) {
          this.queryOptions.push({
            value: this.company.queries[i].id,
            text: this.company.queries[i].name
          });
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SETTINGS.UNABLE_GET'));
      }
    },
    async create_sendout(data) {
      const payload = {
        ...data,
        is_sms: data.type === 'sms',
        is_email: data.type === 'email',
        company_id: this.currentCompanyId,
      }
      try {
        const loader = this.$loading.show();
        const res = await axios.post('/sendout', payload);

        if (res.status === 201) {
          this.selected_sendout = { ...res.data };
        }

        await this.load_sendouts();

        this.$refs['sendout-modal'].hide();
        loader && loader.hide();
        this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SENDOUT.EMPTY_CREATED'));
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_CREATE'));
      }
    },
    delete_sendout_clicked(sendout) {
      this.sendout_id_to_delete = sendout.sendout_id;
      this.$refs['deleteSendoutConfirm'].show();
    },
    async on_delete_sendout_confirm() {
      await this.delete_sendout(this.sendout_id_to_delete);
      this.sendout_id_to_delete = null;
    },
    async delete_sendout(sendout_id) {
      try {
        const res = await axios.delete(`/sendout/${sendout_id}`)

        if (res.status === 204) {
          this.sendouts = this.sendouts.filter(item => item.sendout_id !== sendout_id);
          this.toastr('success', this.$t('COMMON.SUCCESS'), this.$t('PAGES.SENDOUT.DELETED'));
          return
        }

        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_DELETE'));
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_DELETE'));
      }
    },
    async get_sendout(sendout_id) {
      try {
        const { data } = await axios.get(`/sendout/${sendout_id}`);
        return data
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_GET'));
      }
    },
    async load_sendouts() {
      this.sendouts = [];

      try {
        const res = await axios.get(`/sendout?company_id=${this.currentCompanyId}`)

        if (res.status === 200) {
          this.sendouts = res.data;
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_LIST'));
      }
    },

    async start_sendout(sendout) {
      this.sendout_id = sendout.sendout_id;
      this.selected_item = await this.get_sendout(sendout.sendout_id);

      if (!this.selected_item.search_options || this.selected_item.num_to_send === 0) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SENDOUT.NO_RECIPIENTS_ERROR'));

        return;
      }

      this.$refs['confirmModal'].show();
    },
    cancel_confirm_start() {},
    async confirm_start() {

      const loader = this.$loading.show();

      axios
        .post(`/sendout/start/${this.sendout_id}`, {  })
        .then(res => {
          if (res.status === 201) {
            try {
              const index = this.sendouts.findIndex(item => item.sendout_id === this.sendout_id);
              const obj = this.sendouts[index];
              obj.status = 'RUNNING';
              obj.num_sent_ok = '...'

              this.sendouts[index] = obj;
              // Vue3 compatability for Vue.set
              this.sendouts = [...this.sendouts];
            }
            catch (err) {
              console.error('map error: ' + err);
            }

            this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SENDOUT.STARTED'));
          } else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_START'));
          }

          loader && loader.hide();
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_START'));
        });
    },
    get_save_sendout_payload(data) {
      const payload = {
        ...data,
        status: 'DRAFT',
        is_sms: data.type === 'sms',
        is_email: data.type === 'email',
      }

      if (payload.search_options === null) {
        payload.search_options = undefined;
      }

      return payload
    },
    async update_sendout(data) {
      const loader = this.$loading.show();

      try {
        const payload = this.get_save_sendout_payload(data);
        const res = await axios.put(`/sendout/${this.selected_item.sendout_id}`, payload);

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('SENDOUT.UPDATED'));

          await this.load_sendouts();

          this.$refs['sendout-modal'].hide();
          loader && loader.hide();
          return;
        }
      }
      catch (err) {
        console.error(err);
      }

      loader && loader.hide();
      this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('SENDOUT.UNABLE_UPDATE'));
    },
    async send_preview_email(recipient) {
      this.$refs['preview-email-modal'].hide();

      try {
        const res = await axios
          .post(`/sendout/preview/${this.selected_item.sendout_id}?email=${recipient}`, {
            email: recipient,
          })

        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SENDOUT.EMAIL_SENT'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_SEND'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_OCCURED'));
      }
    },
    async send_preview_sms(recipient) {
      this.$refs['preview-sms-modal'].hide();

      try {
        const res = await axios.post(`/sendout/previewsms/${this.selected_item.sendout_id}`, {
          sms: recipient
        })
        if (res.status === 204) {
          this.toastr('success', this.$t('COMMON.OK'), this.$t('PAGES.SENDOUT.SMS_SENT'));
        } else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.SENDOUT.UNABLE_SEND'));
        }
      } catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.ERROR_OCCURED'));
      }
    },
  },
  async mounted() {
    await this.load_data()

    if (this.$route.params.id) {
      this.selected_item = await this.get_sendout(this.$route.params.id);
      this.show_sendout_modal();
    }
  }
};
</script>
